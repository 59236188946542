<script>
export default {
	props: {
		loading: Boolean,
	},
}
</script>
<template>
	<Container class="py-14" width="600" v-if="loading">
		<v-progress-linear color="purple" indeterminate />
	</Container>
	<Container class="pt-14 pb-16" v-else>
		<div class="purple--text font-7 pb-10">
			<slot name="title" />
		</div>
		<slot />
	</Container>
</template>
