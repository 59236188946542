<script>
import { areTranslationsLoaded, addTranslations, langIso } from '@/__shared/lang/lib_lang'

export default {
	lang: 'ourbookspage|content',
	metaInfo() {
		return {
			title: this.loaded ? this.$lang('Mira nuestros cuentos') : '',
		}
	},
	data() {
		return {
			loaded: areTranslationsLoaded('ourbookspage'),
		}
	},
	async created() {
		if (!this.loaded) {
			if (langIso) {
				await this.$shopApi.get('/lang-translations-by-scope', {
					query: { scope: 'ourbookspage', langIso },
					loading: false,
					onSuccess: ({ data }) => {
						addTranslations(data.translations, 'ourbookspage')
					},
				})
			}
		}
		this.loaded = true
		this.$eventer.trigger('page:view')
		this.$prerenderReady()
	},
}
</script>

<template>
	<OtherPageLayout :loading="!loaded">
		<template #title>
			<div class="d-flex align-center">
				<v-icon color="purple" class="mr-0" size="60">mdi-chevron-right</v-icon>
				{{ 'Mira nuestros cuentos' | lang }}
			</div>
		</template>
		<div style="max-width: 900px">
			<div class="purple-box">
				<div
					class="pb-2"
					v-html="
						$lang(
							`Nuestras historias están <b>hechas con mucho amor</b> para que niños y niñas reciban un regalo <b>especial y lleno de magia</b>. Descubrí la temática de nuestros cuentos y qué se personaliza en cada uno de ellos.`
						)
					"
				></div>
				<div
					v-html="
						$lang(
							`Te mostramos un ejemplo de cada historia para que puedas conocerlos mejor y <b>elijas el mejor para tu peque.</b>`
						)
					"
				></div>
			</div>
			<div class="section">
				<p>
					<span class="bookname">{{ '¿Sabés cuánto te quiero?' | lang }}</span>
					{{
						'Se personaliza con el nombre del niño o la niña. El siguiente es un ejemplo de ¿Sabés cuánto te quiero? para una Nena, las ilustraciones se modifican dependiendo del género.'
							| lang
					}}
				</p>
				<p
					class="buttons-cont d-flex align-center justify-center justify-md-start flex-column flex-md-row"
				>
					<Button
						class="mr-md-3 mb-3 mb-md-0 py-2 px-4 py-md-0"
						color="purple"
						href="/previews/sabes-cuanto-te-quiero/index.html"
						target="_blank"
						:small="$vuetify.breakpoint.xs"
					>
						👉 {{ 'MIRAR ¿SABÉS CUÁNTO TE QUIERO?' | lang }}
					</Button>
					<Button color="success" to="/p/sabes-cuanto-te-quiero/bc166380-0fd2-4e9b-adb7-1b1314e6a902">
						<v-icon>mdi-cart</v-icon> {{ '¡COMPRALO ACÁ!' | lang }}
					</Button>
				</p>
				<p>
					<span class="subtitle">{{ '¿Sobre qué trata esta historia?' | lang }}</span>
					{{
						'Le cuenta a tu peque (hijo/a, nieto/a, sobri, ahijado/a) lo infinito que es tu amor. Tan inmenso, que no se puede medir, ni comparar con nada en el mundo. No alcanzan el sol, la luna y el universo entero. Tampoco el agua de todos los mares y océanos. Es más que de aquí a las estrellas ida y vuelta... Pero eso tampoco es suficiente... ¡es más, muchísimo más! En nuestra página vas a encontrar una opción para personalizar el nombre del dedicante.'
							| lang
					}}
				</p>
			</div>
			<div class="section">
				<p>
					<span class="bookname">{{ 'Buenas Noches' | lang }}</span>
					{{
						'Se personaliza tapa e interior de cada página con el nombre del niño o niña. El siguiente es un ejemplo de Buenas Noches para una nena, la gráfica y algunos personajes cambian dependiendo del género.'
							| lang
					}}
				</p>
				<p
					class="buttons-cont d-flex align-center justify-center justify-md-start flex-column flex-md-row"
				>
					<Button
						class="mr-md-3 mb-3 mb-md-0 py-2 px-4 py-md-0"
						color="purple"
						href="/previews/buenas-noches/index.html"
						target="_blank"
						:small="$vuetify.breakpoint.xs"
					>
						👉 {{ 'MIRAR LIBRO BUENAS NOCHES' | lang }}
					</Button>
					<Button color="success" to="/p/buenas-noches/e0b7938d-05cf-4b9d-8b04-db17638dc8e4">
						<v-icon>mdi-cart</v-icon> {{ '¡COMPRALO ACÁ!' | lang }}
					</Button>
				</p>
				<p>
					<span class="subtitle">{{ '¿Sobre qué trata esta historia?' | lang }}</span>
					{{
						'Es un cuento personalizado para la hora de dormir, en el que el o la protagonista tiene el nombre del nene o nena que lo va a recibir. Los acompaña en las rutinas de ponerse el pijama y lavarse los dientes, y responde muchas de las preguntas que los chicos se hacen a la hora de ir a la cama. Les cuenta cómo duermen los animales y qué pasa cuando sueñan, por qué es importante el descanso y dormir solitos, para que puedan disipar miedos, relajarse y tener dulces sueños.'
							| lang
					}}
				</p>
			</div>
			<div class="section">
				<p>
					<span class="bookname">{{ 'La Aventura de mi Cumpleaños' | lang }}</span>
					{{
						'Se personaliza el interior del libro con el nombre del niño o niña, los años que cumple y la fecha de cumpleaños. El siguiente es un ejemplo de La Aventura de mi Cumpleaños para un nene, la gráfica y algunos personajes cambian dependiendo del género. La tapa no es personalizable en esta historia'
							| lang
					}}
				</p>
				<p
					class="buttons-cont d-flex align-center justify-center justify-md-start flex-column flex-md-row"
				>
					<Button
						class="mr-md-3 mb-3 mb-md-0 py-2 px-4 py-md-0"
						color="purple"
						href="/previews/la-aventura-de-mi-cumpleanos/index.html"
						target="_blank"
						:small="$vuetify.breakpoint.xs"
					>
						👉 {{ 'MIRAR LIBRO LA AVENTURA DE MI CUMPLEAÑOS' | lang }}
					</Button>
					<Button
						color="success"
						to="/p/la-aventura-de-mi-cumpleanos/491b3675-d86e-4d01-9ff8-25a3623aa7a7"
					>
						<v-icon>mdi-cart</v-icon> {{ '¡COMPRALO ACÁ!' | lang }}
					</Button>
				</p>
				<p>
					<span class="subtitle">{{ '¿Sobre qué trata esta historia?' | lang }}</span>
					{{
						'Es un cuento personalizado para ese día que los chicos esperan todo el año: ¡su cumpleaños! El protagonista del cuento cambia de acuerdo al nombre del niño o la niña a quien se lo regalarás y los años que cumple. Los invita a vivir un viaje mágico a bordo de una cigüeña, en el que se encuentran con adorables personajes: magos o hadas, simpáticos ogros o brujas, leones o monitos. La historia que cuenta los invita a pensar sobre sus acciones y promueve valores como la generosidad, el trabajo en equipo y la solidaridad.'
							| lang
					}}
				</p>
			</div>
			<div class="section">
				<p>
					<span class="bookname">{{ 'Mi nueva hermanita / Mi nuevo hermanito' | lang }}</span>
					{{
						'Se personaliza con el nombre de ambos herman@s en el interior y con el nombre del herman@ mayor en tapa. Los siguientes son ejemplos, la gráfica se modifica con el género del hermano o hermana mayor.'
							| lang
					}}
				</p>
				<p
					class="buttons-cont d-flex align-center justify-center justify-md-start flex-column flex-md-row"
				>
					<Button
						class="mr-md-3 mb-3 mb-md-0 py-2 px-4 py-md-0"
						color="purple"
						href="/previews/mi-nueva-hermanita/index.html"
						target="_blank"
						:small="$vuetify.breakpoint.xs"
					>
						👉 {{ 'MIRAR MI NUEVA HERMANITA' | lang }}
					</Button>
					<Button
						class="mr-md-3 mb-3 mb-md-0 py-2 px-4 py-md-0"
						color="purple"
						href="/previews/mi-nuevo-hermanito/index.html"
						target="_blank"
						:small="$vuetify.breakpoint.xs"
					>
						👉 {{ 'MIRAR MI NUEVO HERMANITO' | lang }}
					</Button>
					<Button
						color="success"
						to="/p/mi-nuevo-hermanito-mi-nueva-hermanita/73f84c02-2075-4e46-a6a2-ddb725a62d73"
					>
						<v-icon>mdi-cart</v-icon> {{ '¡COMPRALO ACÁ!' | lang }}
					</Button>
				</p>
				<p>
					<span class="subtitle">{{ '¿Sobre qué trata esta historia?' | lang }}</span>
					{{
						'Es un cuento personalizado para niñ@s que están por tener o ya tuvieron una nueva hermanita o hermanito. La historia se personaliza con el nombre del hermano o hermana mayor en tapa y el de ambos herman@s en páginas interiores. Está narrado desde el punto de vista del hermano o hermana mayor y refleja lo que sienten durante ese proceso de espera, nacimiento y llegada de la nueva beba o bebé a la familia. El cuento los ayuda a entender que siempre van a ser muy querid@s y refuerza el vínculo de amor entre herman@s para que puedan descubrir lo hermoso que es haber ganado un/una compañer@ de juegos y aventuras para toda la vida'
							| lang
					}}
				</p>
			</div>
			<div class="section">
				<p>
					<span class="bookname">{{ 'La Magia de los colores' | lang }}</span>
					{{
						'Se personaliza tapa e interior de cada página con el nombre del niño o niña. El siguiente es un ejemplo de la Magia de los Colores para una nena, la gráfica cambia dependiendo del género.'
							| lang
					}}
				</p>
				<p
					class="buttons-cont d-flex align-center justify-center justify-md-start flex-column flex-md-row"
				>
					<Button
						class="mr-md-3 mb-3 mb-md-0 py-2 px-4 py-md-0"
						color="purple"
						href="/previews/la-magia-de-los-colores/index.html"
						target="_blank"
						:small="$vuetify.breakpoint.xs"
					>
						👉 {{ 'MIRAR LA MAGIA DE LOS COLORES' | lang }}
					</Button>
					<Button color="success" to="/p/la-magia-de-los-colores/6d55ada4-65fd-45a4-953c-2bf8cdde54f6">
						<v-icon>mdi-cart</v-icon> {{ '¡COMPRALO ACÁ!' | lang }}
					</Button>
				</p>
				<p>
					<span class="subtitle">{{ '¿Sobre qué trata esta historia?' | lang }}</span>
					{{
						'Es un cuento personalizado que invita a niños y niñas a descubrir el maravilloso mundo de los colores y a jugar con los que encuentran a su alrededor. También les enseña “trucos de magia” para que puedan crear muchos otros colores nuevos.'
							| lang
					}}
				</p>
			</div>
			<div class="section">
				<p>
					<span class="bookname">{{ 'Disney Frozen "Un cuento para..."' | lang }}</span>
					{{
						'Se personaliza con el nombre del niño o la niña. El siguiente es un ejemplo de Disney Frozen "Un cuento para..."  para una nena.'
							| lang
					}}
				</p>
				<p
					class="buttons-cont d-flex align-center justify-center justify-md-start flex-column flex-md-row"
				>
					<Button
						class="mr-md-3 mb-3 mb-md-0 py-2 px-4 py-md-0"
						color="purple"
						href="/previews/disney-frozen/index.html"
						target="_blank"
						:small="$vuetify.breakpoint.xs"
					>
						👉 {{ 'MIRAR Disney Frozen "Un cuento para..."' | lang }}
					</Button>
					<Button
						color="success"
						to="/p/disney-frozen-un-cuento-para/e4ecdb7d-ecbb-4a44-988a-d9ea3789254c"
					>
						<v-icon>mdi-cart</v-icon> {{ '¡COMPRALO ACÁ!' | lang }}
					</Button>
				</p>
				<p>
					<span class="subtitle">{{ '¿Sobre qué trata esta historia?' | lang }}</span>
					{{
						'Anna y Elsa son dos hermanas que adoran jugar juntas. Pero Elsa tiene poderes de hielo y, el día en que es coronada reina, sin querer congela el reino de Arendelle. Temerosa de herir a su hermana o a los habitantes del reino, decide huir a la Montaña del Norte.'
							| lang
					}}
				</p>
				<p>
					{{
						'En este cuento, tu peque va a entrar en el maravilloso universo de Frozen y acompañará a Anna y sus amigos en esta aventura congelada para encontrar a Elsa, y que Arendelle pueda recuperar el verano. Regalale a tu peque la posibilidad de ser protagonista de esta hermosa historia sobre el poder de la amistad y el amor.'
							| lang
					}}
				</p>
			</div>
			<div class="section">
				<p>
					<span class="bookname">{{ 'Toy Story "Un cuento para..."' | lang }}</span>
					{{
						'Se personaliza con el nombre del niño o la niña. El siguiente es un ejemplo de Disney Toy Story "Un cuento para..." para una nene:'
							| lang
					}}
				</p>
				<p
					class="buttons-cont d-flex align-center justify-center justify-md-start flex-column flex-md-row"
				>
					<Button
						class="mr-md-3 mb-3 mb-md-0 py-2 px-4 py-md-0"
						color="purple"
						href="/previews/toy-story-un-cuento-para/index.html"
						target="_blank"
						:small="$vuetify.breakpoint.xs"
					>
						👉 {{ 'MIRAR Toy Story "Un cuento para..."' | lang }}
					</Button>
					<Button
						color="success"
						to="/p/disney-toy-story-un-cuento-para/04a5b770-088a-4b93-8dec-0f92ab2f9367"
					>
						<v-icon>mdi-cart</v-icon> {{ '¡COMPRALO ACÁ!' | lang }}
					</Button>
				</p>
				<p>
					<span class="subtitle">{{ '¿Sobre qué trata esta historia?' | lang }}</span>
					{{
						'Woody y sus amigos tienen una nueva dueña, Bonnie. En su primer día de jardín, la niña arma a Forky: su nuevo juguete favorito. Pero Forky se mete en grandes problemas intentando escapar, y Woody tiene que correr a rescatarlo.'
							| lang
					}}
				</p>
				<p>
					{{
						'En este cuento, tu peque va a entrar en el universo de Toy Story 4 para vivir emocionantes aventuras junto con Woody y Bo Peep. Juntos, armarán un plan para recuperar a Forky y llevarlo de regreso con Bonnie.'
							| lang
					}}
				</p>
			</div>
			<div class="section">
				<p>
					<span class="bookname">{{ 'Disney Baby "¡Vamos a dormir!"' | lang }}</span>
					{{
						'Se personaliza con el nombre del niño o la niña. El siguiente es un ejemplo de Disney Baby "Un cuento para..." para una nene:'
							| lang
					}}
				</p>
				<p
					class="buttons-cont d-flex align-center justify-center justify-md-start flex-column flex-md-row"
				>
					<Button
						class="mr-md-3 mb-3 mb-md-0 py-2 px-4 py-md-0"
						color="purple"
						href="/previews/disney-baby-vamos-a-dormir/index.html"
						target="_blank"
						:small="$vuetify.breakpoint.xs"
					>
						👉 {{ 'MIRAR Disney Baby "¡Vamos a dormir!"' | lang }}
					</Button>
					<Button
						color="success"
						to="/p/disney-baby-vamos-a-dormir/77e271eb-96fa-443a-9df6-3c8e993762c2"
					>
						<v-icon>mdi-cart</v-icon> {{ '¡COMPRALO ACÁ!' | lang }}
					</Button>
				</p>
				<p>
					<span class="subtitle">{{ '¿Sobre qué trata esta historia?' | lang }}</span>
					{{
						'¡Vamos a dormir! Es un cuento personalizado con ilustraciones originales de Baby Disney, para acompañar a los y las más pequeñas en su rutina de ir a la cama. El niño o la niña a quien se dedique este libro protagonizará una historia donde imitará a sus personajes favoritos cuando guarden los juguetes, cepillen sus dientes o escuchen un cuento... ¡Todo lo que hacemos antes de dormir!'
							| lang
					}}
				</p>
			</div>
			<div class="section">
				<p>
					<span class="bookname">{{ 'En la Tierra de los Dinosaurios' | lang }}</span>
					{{
						'Se personaliza con el nombre y género del niño o la niña. El siguiente es un ejemplo de “En la Tierra de los Dinosaurios” para una nena:'
							| lang
					}}
				</p>
				<p
					class="buttons-cont d-flex align-center justify-center justify-md-start flex-column flex-md-row"
				>
					<Button
						class="mr-md-3 mb-3 mb-md-0 py-2 px-4 py-md-0"
						color="purple"
						href="/previews/en-la-tierra-de-los-dinosaurios/index.html"
						target="_blank"
						:small="$vuetify.breakpoint.xs"
					>
						👉 {{ 'MIRAR En la Tierra de los Dinosaurios' | lang }}
					</Button>
					<Button
						color="success"
						to="/p/en-la-tierra-de-los-dinosaurios/5b9d4bd4-a1ea-4605-873f-acfc55ce26e0"
					>
						<v-icon>mdi-cart</v-icon> {{ '¡COMPRALO ACÁ!' | lang }}
					</Button>
				</p>
				<p>
					<span class="subtitle">{{ '¿Sobre qué trata esta historia?' | lang }}</span>
					{{
						'Es un cuento personalizado para todos los y las peques que adoran explorar. Tu peque protagonizará una tierna historia en la tierra de los dinosaurios, junto con su nuevo amigo Dino. El o la protagonista de esta historia visitará tiempos pre-históricos para ayudar a su gigantesco compañero a descubrir quién es y encontrar a su familia.'
							| lang
					}}
				</p>
			</div>
		</div>
	</OtherPageLayout>
</template>

<style lang="scss" scoped>
.section {
	padding: 20px 28px;
	border: 1px solid var(--v-purple-lighten5);
	border-radius: 16px;
	margin: 36px 0;
}
.v-icon {
	margin-right: 4px;
}

.bookname {
	color: var(--v-purple-base);
	@include fontSize(5);
	font-weight: bold;
	padding-bottom: 12px;
	display: block;
}
.subtitle {
	color: var(--v-purple-base);
	@include fontSize(1);
	padding-top: 12px;
	padding-bottom: 8px;
	display: block;
	font-weight: bold;
}
b {
	color: var(--v-purple-base);
}
.purple-box {
	padding: 20px 28px;
	border-radius: 16px;
	background: rgba(0, 0, 0, 0.08);
	color: var(--v-purple-base);
}
</style>
