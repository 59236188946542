<script>
import { areTranslationsLoaded, addTranslations, langIso } from '@/__shared/lang/lib_lang'
import termsHtml from 'raw-loader!./terms-conditions-content.html'

export default {
	lang: 'termspage|content',
	metaInfo() {
		return {
			title: this.loaded ? this.$lang('Términos y condiciones') : '',
		}
	},
	data() {
		return {
			loaded: areTranslationsLoaded('termspage'),
		}
	},
	async created() {
		if (!this.loaded) {
			if (langIso) {
				await this.$shopApi.get('/lang-translations-by-scope', {
					query: { scope: 'termspage', langIso },
					loading: false,
					onSuccess: ({ data }) => {
						addTranslations(data.translations, 'termspage')
					},
				})
			}
		}
		this.loaded = true
		this.$eventer.trigger('page:view')
		this.$prerenderReady()
	},
	methods: {
		getTermsHtml() {
			let html = this.$langText('terms-and-conditions-html')
			return html == 'terms-and-conditions-html' ? termsHtml : html
		},
	},
}
</script>

<template>
	<OtherPageLayout :loading="!loaded">
		<template #title> {{ 'Términos y condiciones' | lang }} </template>
		<div v-html="getTermsHtml()" />
	</OtherPageLayout>
</template>
